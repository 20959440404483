import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Popup from '../components/common/popup/Popup';
import Popup220318 from '../assets/popup/Popup220318';
import PopupConsult from '../assets/popup/PopupConsult';

import styles from '../styles/pages/HsIndex.module.scss';

import serviceTason from "../assets/images/index_service_tason.jpg"
import serviceCloud from "../assets/images/index_service_cloud.jpg";
import clientLogo from "../assets/images/index_client_logo.png";
import clientLogoM from "../assets/images/index_client_logo_m.png";
import contactButtonM from "../assets/images/index_contact_button.svg";
import { toBoolean } from '../components/common/common';
import { useStore } from 'react-redux';

function HsIndex() {
    const [showPopup, setShowPopup] = useState(false);
    const [showConsultPopup, setShowConsultPopup] = useState(false);

    const store = useStore();

    useEffect(() => {
        const _today = new Date().toISOString().slice(0, 10);
        const isPopupShown = localStorage.getItem('isPopupShown');
        const isPopupShownToday = localStorage.getItem('isPopupShownToday');

        if ((!isPopupShown || toBoolean(isPopupShown)) && isPopupShownToday !== _today) {
            setShowPopup(true);
        }
        const { hash } = window.location;
        const section = document.getElementById(hash.replace('#', ''));
        if (section) {
            setTimeout(() => {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 100);
        }
    }, []);

    const handleShowConsultPopup = () => {
        setShowConsultPopup(true);
    }

    function handleDismissClick() {
        localStorage.setItem('isPopupShown', false);
        setShowPopup(false);
    }

    function handleDismissClickForDay() {
        const today = new Date().toISOString().slice(0, 10);
        localStorage.setItem('isPopupShownToday', today);
        setShowPopup(false);
    }

    function handleClose() {
        setShowConsultPopup(false);
    }

    function handleTodayClose() {
        setShowPopup(false);
    }

    function requestConsult(formData) {
        const { company, name, contact, email, content } = formData;

        if(!company || company.trim() === '') {
            alert('회사명/소속을 입력해주세요.');
            return;
        } else if(!name || name.trim() === '') {
            alert('성함/직책을 입력해주세요.');
            return;
        } else if(!contact || contact.trim() === '') {
            alert('연락처를 입력해주세요.');
            return;
        } else if(!email || email.trim() === '') {
            alert('메일주소를 입력해주세요.');
            return;
        } else if(!content || content.trim() === '') {
            alert('문의내용을 입력해주세요.');
            return;
        }

        let keyword = '';
        const type = 1; // qna일 경우 1, 서비스 소개서 신청일 경우 2
        const volume = '0';
        const params = {
            type,
            company,
            name,
            contact,
            email,
            content,
            keyword,
            volume
        }
        const url = "https://imc.humuson.com/api/contact/v1/sendMail";
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then((res) => {
                alert(res.message);
                setShowConsultPopup(false);
            })
            .catch(error => {
                // 오류 처리
            });
    }

    function handleClick() {
        const { consultData } = store.getState().popupDataReducer;
        requestConsult(consultData);
    }

    return (
        <>
            <section className={styles['hs-visual-section']}>
                <h2 className="blind">디지털 마케팅 플랫폼, 휴머스온</h2>
                <div className={styles['hs-visual-section-inner']}>
                    <h2 className={styles['hs-visual-section-title']}>1등 기업이 선택하는 <span className={styles['point']}>디지털 마케팅 플랫폼</span> <span className="blind">휴머스온</span></h2>
                    <ul className={styles['hs-visual-section-list']}>
                        <li className={styles['hs-visual-section-item']}>인공지능 마케팅 플랫폼 <strong>AI Help U</strong></li>
                        <li className={styles['hs-visual-section-item']}>빅데이터 마케팅 플랫폼 <strong>TasOn</strong></li>
                        <li className={styles['hs-visual-section-item']}>통합 채널 메시징 솔루션 <strong>TMS</strong></li>
                    </ul>
                </div>
            </section>
            <section className="hs-section display-pc">
                <h2 className="blind">디지털 마케팅 플랫폼, 휴머스온이 제공하는 서비스</h2>
                <div className="hs-section-inner">
                    <dl className={styles['hs-intro-info-list']}>
                        <div className={`${styles['hs-intro-info-item']} ${styles['collection']}`}>
                            <dt className={styles['hs-intro-info-title']}>데이터 수집</dt>
                            <dd className={styles['hs-intro-info-desc']}>
                                <ul className={styles['hs-intro-info-sub-list']}>
                                    <li className={styles['hs-intro-info-sub-item']}>국내 <strong>1위</strong> 이커머스 마케팅 데이터</li>
                                    <li className={styles['hs-intro-info-sub-item']}>디지털 마케팅솔루션 국내 <strong>1위</strong></li>
                                    <li className={styles['hs-intro-info-sub-item']}>누적 기업고객 <strong>100,000 개사</strong></li>
                                </ul>
                            </dd>
                        </div>
                        <div className={`${styles['hs-intro-info-item']} ${styles['analysis']}`}>
                            <dt className={styles['hs-intro-info-title']}>데이터 분석</dt>
                            <dd className={styles['hs-intro-info-desc']}>
                                <ul className={styles['hs-intro-info-sub-list']}>
                                    <li className={styles['hs-intro-info-sub-item']}>실시간 고객행동 빅데이터 분석</li>
                                    <li className={styles['hs-intro-info-sub-item']}><strong>On&Off</strong> 통합 데이터 분석</li>
                                    <li className={styles['hs-intro-info-sub-item']}><strong>3rd Party Data (Out Tag)</strong> 분석</li>
                                </ul>
                            </dd>
                        </div>
                        <div className={`${styles['hs-intro-info-item']} ${styles['application']}`}>
                            <dt className={styles['hs-intro-info-title']}>데이터 활용</dt>
                            <dd className={styles['hs-intro-info-desc']}>
                                <ul className={styles['hs-intro-info-sub-list']}>
                                    <li className={styles['hs-intro-info-sub-item']}>통합 메시징 국내 <strong>1위</strong> 기술</li>
                                    <li className={styles['hs-intro-info-sub-item']}>빅데이터 기반 <strong>AI</strong> 상품 추천</li>
                                    <li className={styles['hs-intro-info-sub-item']}>데이터 기반 디지털 타겟팅 광고</li>
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>
            </section>
            <section className="hs-section gray" id="ai-help-u">
                <h2 className="blind">AI Help U</h2>
                <div className="hs-section-inner">
                    <h3 className={styles['hs-section-title']}><span className={styles['hs-section-title-underline']}>AI Help U</span> 인공지능 마케팅 플랫폼</h3>
                    <p className={styles['hs-section-sub-title']}>정보통신산업진흥원 <strong>[2021 AI 솔루션 바우처 공급기업 선정]</strong></p>
                    <dl className={styles['hs-section-ai-list']}>
                        <div className={`${styles['hs-section-ai-item']} ${styles.recommend}`}>
                            <dt className={styles['hs-section-ai-title']}>인공지능 <strong>상품 추천</strong></dt>
                            <dd className={styles['hs-section-ai-text']}>RNN, CNN 알고리즘으로 최적의 상품을 찾아 고객의 구매 의욕을 높이세요.</dd>
                        </div>
                        <div className={`${styles['hs-section-ai-item']} ${styles.communication}`}>
                            <dt className={styles['hs-section-ai-title']}>인공지능 <strong>커뮤니케이션 최적화</strong></dt>
                            <dd className={styles['hs-section-ai-text']}>고객에게 가장 적합한 커뮤니케이션 채널, 최적 타이밍, 메시지 피로도 등을 자동화하세요.</dd>
                        </div>
                        <div className={`${styles['hs-section-ai-item']} ${styles.ad}`}>
                            <dt className={styles['hs-section-ai-title']}>인공지능 <strong>광고 집행 자동화</strong></dt>
                            <dd className={styles['hs-section-ai-text']}>AI로 광고 효능을 판단하여 캠페인 예산을 자동 조절하고 최적의 ROAS를 달성하세요.</dd>
                        </div>
                        <div className={`${styles['hs-section-ai-item']} ${styles.report}`}>
                            <dt className={styles['hs-section-ai-title']}>인공지능 <strong>경쟁사 분석 리포트</strong></dt>
                            <dd className={styles['hs-section-ai-text']}>고객, 자사, 경쟁사를 AI가 분석해드립니다. 경쟁사의 현황을 파악하고 앞서가세요.</dd>
                        </div>
                    </dl>
                    <div className={`${styles['hs-contact-button-wrap']} display-m`}>
                        <button type="button" className={styles['hs-contact-button-link']} onClick={handleShowConsultPopup}>
                            <img src={contactButtonM} alt="문의하기" className={styles['hs-contact-button']} />
                        </button>
                    </div>
                </div>
                <div className="hs-section-inner">
                    <h3 className={styles['hs-section-title']}><span className={styles['hs-section-title-underline']}>AI Help U</span> 인공지능 기업 컨설팅</h3>
                    <ul className={styles['hs-section-ai-consulting-list']}>
                        <li className={`${styles['hs-section-ai-consulting-item']} ${styles.kubeflow}`}>ML Ops <strong>Kubeflow</strong></li>
                        <li className={`${styles['hs-section-ai-consulting-item']} ${styles.dataset}`}>인공지능 <strong>데이터셋</strong></li>
                        <li className={`${styles['hs-section-ai-consulting-item']} ${styles.algorithm}`}>인공지능 <strong>알고리즘</strong></li>
                        <li className={`${styles['hs-section-ai-consulting-item']} ${styles.performance}`}>인공지능 <strong>성과평가</strong></li>
                        <li className={`${styles['hs-section-ai-consulting-item']} ${styles.saas}`}>인공지능 <strong>SaaS</strong></li>
                    </ul>
                    <div className={`${styles['hs-contact-button-wrap']} display-m`}>
                        <button type="button" className={styles['hs-contact-button-link']} onClick={handleShowConsultPopup}>
                            <img src={contactButtonM} alt="문의하기" className={styles['hs-contact-button']} />
                        </button>
                    </div>
                </div>
            </section>
            <section className="hs-section">
                <div className="hs-section-inner">
                    <h2 className={styles['hs-section-title']}><span className={styles['hs-section-title-underline']}>TasOn</span> 빅데이터 마케팅 플랫폼</h2>
                    <ul className={styles['hs-section-service-list']}>
                        <li className={`${styles['hs-section-service-item']} ${styles.targeting}`}>
                            <Link to="https://www.tason.com/" target="_blank" className={styles['hs-section-service-link']}>
                                <span className={styles['hs-section-service-label']}>SERVICE</span>
                                <strong className={styles['hs-section-service-title']}>Targeting Automation Service</strong>
                                <div className={styles['hs-section-service-contents']}>
                                    <ul className={styles['hs-section-service-tag-list']}>
                                        <li className={styles['hs-section-service-tag-item']}># 고객 관심사</li>
                                        <li className={styles['hs-section-service-tag-item']}># 개인화 마케팅</li>
                                    </ul>
                                    <p className={styles['hs-section-service-desc']}>웹/앱 방문 고객의 행동 데이터, 관심 키워드 기반으로 마케팅 실행, 고객 관심사에 따라 개인화 메시지를 실시간으로 발송합니다.</p>
                                </div>
                                <div className={styles['hs-section-service-button-wrap']}>
                                    <span className={styles['hs-section-service-button']}>서비스 바로가기</span>
                                </div>
                            </Link>
                        </li>
                        <li className={`${styles['hs-section-service-item']} ${styles.tason}`}>
                            <Link to="https://www.tason.com/to/ma" target="_blank" className={styles['hs-section-service-link']}>
                                <span className={styles['hs-section-service-label']}>SERVICE</span>
                                <strong className={styles['hs-section-service-title']}>TasOn Marketing Automation</strong>
                                <div className={styles['hs-section-service-contents']}>
                                    <ul className={styles['hs-section-service-tag-list']}>
                                        <li className={styles['hs-section-service-tag-item']}># 마케팅 자동화</li>
                                    </ul>
                                    <p className={styles['hs-section-service-desc']}>고객의 온라인 행동 데이터 수집부터 캠페인 실행, 성과분석까지 자동화됩니다.</p>
                                </div>
                                <img className={styles['hs-section-service-image']} src={serviceTason} alt="" />
                                <dl className="blind">
                                    <dt>고객이 사이트 내 체류 시</dt>
                                    <dd>TasOn 마케팅 자동화: <strong>온사이트 팝업</strong>을 통한 구매전환 극대화</dd>
                                    <dt>고객이 구매없이 사이트 이탈시</dt>
                                    <dd>TasOn 마케팅 자동화: 메일, SMS, 앱푸시, 카카오톡 등 <strong>메시지 채널 기반</strong> 자동화된 캠페인으로 재방문 유도</dd>
                                </dl>
                                <div className={styles['hs-section-service-button-wrap']}>
                                    <span className={styles['hs-section-service-button']}>서비스 바로가기</span>
                                </div>
                            </Link>
                        </li>
                        <li className={`${styles['hs-section-service-item']} ${styles.tms}`}>
                            <Link to="/tms" target="_blank" className={styles['hs-section-service-link']}>
                                <span className={styles['hs-section-service-label']}>SOLUTION</span>
                                <strong className={styles['hs-section-service-title']}>TMS Enterprise Solution</strong>
                                <div className={styles['hs-section-service-contents']}>
                                    <ul className={styles['hs-section-service-tag-list']}>
                                        <li className={styles['hs-section-service-tag-item']}># 통합채널 & 마케팅솔루션</li>
                                    </ul>
                                    <p className={styles['hs-section-service-desc']}>이메일, 푸시, 문자, 카카오비즈 메시지 등의 채널 통합과 실시간 데이터를 기반으로 마케팅 자동화를 지원합니다.</p>
                                </div>
                                <div className={styles['hs-section-service-button-wrap']}>
                                    <span className={styles['hs-section-service-button']}>서비스 바로가기</span>
                                </div>
                            </Link>
                        </li>
                        <li className={`${styles['hs-section-service-item']} ${styles.hcloud}`}>
                            <button type="button" onClick={handleShowConsultPopup} className={styles['hs-section-service-link']}>
                                <span className={styles['hs-section-service-label']}>SOLUTION</span>
                                <strong className={styles['hs-section-service-title']}>H Cloud</strong>
                                <div className={styles['hs-section-service-contents']}>
                                    <ul className={styles['hs-section-service-tag-list']}>
                                        <li className={styles['hs-section-service-tag-item']}># Data Marketing Cloud</li>
                                    </ul>
                                    <p className={styles['hs-section-service-desc']}>데이터 확장성과 외부 연결성을 보장하기 위해 Cloud 기반으로 구성</p>
                                    <p className={styles['hs-section-service-desc']}>On-premise 솔루션 구축과 비교하여 비용이 1/2, 구축기간 only 8weeks</p>
                                </div>

                                <img className={styles['hs-section-service-image']} src={serviceCloud} alt="" />
                                <dl className="blind">
                                    <dt>문자</dt>
                                    <dd>짧은 문자, 긴 문자, 그림 문자</dd>
                                    <dt>이메일</dt>
                                    <dd>대량 발송, AD Sharing</dd>
                                    <dt>웹푸시</dt>
                                    <dd>실시간 발송, 온사이트 팝업</dd>
                                    <dt>카카오</dt>
                                    <dd>알림톡, 친구톡, 상담톡, 카카오챗봇, 카카오싱크</dd>
                                    <dt>CBDM</dt>
                                    <dd>중국형 메시지, 해외문자</dd>
                                    <dt>네이버</dt>
                                    <dd>네이버톡톡, 스마트플러스</dd>
                                </dl>
                                <div className={styles['hs-section-service-button-wrap']}>
                                    <span className={styles['hs-section-service-button']}>서비스 바로가기</span>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="hs-section gray" id="cmc">
                <h2 className="blind">TMS</h2>
                <div className="hs-section-inner">
                    <h3 className={styles['hs-section-title']}>
                        <span className={styles['hs-section-title-underline']}>TMS</span> 통합 마케팅 솔루션
                    </h3>
                    <div className={styles['hs-section-circle-wrap']}>
                        <div className={styles['hs-section-circle']}>
                            <strong className={styles['hs-section-circle-title']}>Cross Channel & Bigdata Marketing Solution</strong>
                            <span className={styles['hs-section-circle-desc']}>통합채널 & 마케팅자동화</span>
                        </div>
                        <dl className={styles['hs-section-circle-list']}>
                            <div className={`${styles['hs-section-circle-item']} ${styles.message}`}>
                                <dt className={styles['hs-section-circle-item-title']}>문자</dt>
                                <dd className={styles['hs-section-circle-item-desc']}>짧은 문자, 긴 문자, <br aria-hidden="true" />그림 문자</dd>
                            </div>
                            <div className={`${styles['hs-section-circle-item']} ${styles.email}`}>
                                <dt className={styles['hs-section-circle-item-title']}>이메일</dt>
                                <dd className={styles['hs-section-circle-item-desc']}>대량 발송 <br aria-hidden="true" />AD Sharing</dd>
                            </div>
                            <div className={`${styles['hs-section-circle-item']} ${styles.push}`}>
                                <dt className={styles['hs-section-circle-item-title']}>웹푸시</dt>
                                <dd className={styles['hs-section-circle-item-desc']}>실시간 발송 <br aria-hidden="true" />온사이트 팝업</dd>
                            </div>
                            <div className={`${styles['hs-section-circle-item']} ${styles.kakao}`}>
                                <dt className={styles['hs-section-circle-item-title']}>카카오</dt>
                                <dd className={styles['hs-section-circle-item-desc']}>알림톡, 친구톡, 상담톡, <br aria-hidden="true" />카카오챗봇, 카카오싱크</dd>
                            </div>
                            <div className={`${styles['hs-section-circle-item']} ${styles.cbdm}`}>
                                <dt className={styles['hs-section-circle-item-title']}>CBDM</dt>
                                <dd className={styles['hs-section-circle-item-desc']}>중국형 메시지 <br aria-hidden="true" />해외문자</dd>
                            </div>
                            <div className={`${styles['hs-section-circle-item']} ${styles.naver}`}>
                                <dt className={styles['hs-section-circle-item-title']}>네이버</dt>
                                <dd className={styles['hs-section-circle-item-desc']}>네이버톡톡 <br aria-hidden="true" />스마트플러스</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
            <section className="hs-section" id="aid">
                <h2 className="blind">AID</h2>
                <div className="hs-section-inner">
                    <h3 className={styles['hs-section-title']}>
                        <span className={styles['hs-section-title-underline']}>AID</span> 데이터 기반의 AI 광고
                    </h3>
                    <div className="hs-section-item">
                        <div className={styles['hs-section-data-list-wrap']}>
                            <div className={styles['hs-section-data-title-wrap']}>
                                <div className={styles['hs-section-data-title']}>
                                    <strong className={styles['hs-section-data-title-strong']}>AI Data</strong>
                                    <span className={styles['hs-section-data-title-text']}>데이터 기반 광고</span>
                                </div>
                                <p className={styles['hs-section-data-title-desc']}>
                                    Behavioral Data, Demographic Data, Lifestyle, Interests Data <br aria-hidden="true" />
                                    Data based AI Targeting & Digital Media Mix strategy
                                </p>
                            </div>
                            <dl className={styles['hs-section-data-list']}>
                                <div className={`${styles['hs-section-data-item']} ${styles.sns}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        SNS Targeting
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        관심사 데이터 기반의 AI Targeting
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.creator}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        Creator
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        디지털 미디어에 적합한 Creative 제작
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.media}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        Media Mix
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        수신자 반응 AI <br aria-hidden="true"/>최적화
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.report}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        AI Report
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        광고 효과 통합 분석 및 리포팅 자동화
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.platform}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        AD Tech Platform
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        행동 기반 Programmatic DA
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className="hs-section-item">
                        <div className={`${styles['hs-section-data-list-wrap']} ${styles.collaboration}`}>
                            <div className={styles['hs-section-data-title-wrap']}>
                                <div className={styles['hs-section-data-title']}>
                                    <strong className={styles['hs-section-data-title-strong']}>Digital Collaboration</strong>
                                </div>
                            </div>
                            <dl className={styles['hs-section-data-list']}>
                                <div className={`${styles['hs-section-data-item']} ${styles.distribution}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        유통
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        On&Off 데이터 통합 <br aria-hidden="true" />
                                        + <br aria-hidden="true" />
                                        CDP
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.finance}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        금융
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        DMP <br aria-hidden="true" />
                                        + <br aria-hidden="true" />
                                        MA
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.service}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        서비스
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        CRM <br aria-hidden="true" />
                                        + <br aria-hidden="true" />
                                        MA
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.manufacturing}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        제조
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        Digital Transformation <br aria-hidden="true" />
                                        + <br aria-hidden="true" />
                                        AI
                                    </dd>
                                </div>
                                <div className={`${styles['hs-section-data-item']} ${styles.smb}`}>
                                    <dt className={styles['hs-section-data-item-title']}>
                                        SMB
                                    </dt>
                                    <dd className={styles['hs-section-data-item-desc']}>
                                        Digital AD <br aria-hidden="true" />
                                        + <br aria-hidden="true" />
                                        MA
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hs-section gray">
                <h2 className="blind">고객사</h2>
                <div className="hs-section-inner">
                    <h3 className={styles['hs-section-title']}>
                        이미 많은 고객사들이 <br aria-hidden="true"/><strong>휴머스온</strong>과 함께 하고 있습니다.
                    </h3>
                    <picture>
                        <source media="(min-width: 992px)" srcSet={clientLogo} />
                        <img src={clientLogoM} alt="이미 많은 고객사들이 휴머스온과 함께하고 있습니다." />
                    </picture>
                    <ul className="blind">
                        <li>삼성</li>
                        <li>현대</li>
                        <li>한국은행</li>
                        <li>미래에셋증권</li>
                        <li>한화투자증권</li>
                        <li>LG</li>
                        <li>국세청</li>
                        <li>NH투자증권</li>
                        <li>GS</li>
                        <li>SK증권</li>
                        <li>BMW</li>
                        <li>LOREAL</li>
                        <li>Homeplus</li>
                        <li>CJ</li>
                        <li>emart</li>
                        <li>배달의 민족</li>
                        <li>STARBUCKS</li>
                        <li>CAFE24</li>
                        <li>SANDBOX</li>
                    </ul>
                </div>
            </section>

            {showPopup && (
                <Popup
                    contentTitle={"신규 서비스 개편 안내"}
                    contentComponent={Popup220318}
                    popupConstructor={'Popup220318'}
                    dismissClick={handleDismissClick}
                    dismissClickForDay={handleDismissClickForDay}
                    onClose={handleTodayClose}
                    contentImage
                    contentCheck
                />
            )}

            {
                showConsultPopup &&
                <Popup
                    onClose={handleClose}
                    contentTitle={"문의/상담 신청"}
                    contentComponent={PopupConsult}
                    popupConstructor={'PopupConsult'}
                    buttonPrimary={"보내기"}
                    buttonPrimaryClick={(data) => handleClick(data)}
                />
            }
        </>
    );
}

export default HsIndex;