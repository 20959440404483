import React from "react";
import { Link } from 'react-router-dom';
import styles from '../styles/pages/HsTermsLayout.module.scss';

function HsTermsPrivacy() {
    return (
        <>
            <p className={styles['hs-terms-section']}>주식회사 휴머스온(이하 "회사")는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」(이하 "정보통신망법") 등 관련 법령상의 개인정보 보호규정을 준수하며, 정보통신망법에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 본 개인정보처리방침은 회사가 제공하는 TAS서비스(TMA 서비스 포함)에 적용되며 다음과 같은 내용을 포함하고 있습니다.</p>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>가. 개인정보 수집 및 이용 현황</h3>
                <p className={styles['hs-terms-section-text']}>회사는 서비스 제공을 위한 최소한의 범위 내에서 이용자의 동의 하에 개인정보를 수집하며, 수집한 모든 개인정보는 고지한 목적 범위 내에서만 사용됩니다.</p>
                <div className={styles['hs-terms-table-wrap']}>
                    <table className={styles['hs-terms-table']}>
                        <caption className={styles['hs-terms-table-caption']}>수집정보</caption>
                        <colgroup>
                            <col style={{ width: "17%" }} />
                            <col style={{ width: "41.5%" }} />
                            <col style={{ width: "41.5%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>수집방법</th>
                                <th>수집항목</th>
                                <th>수집 및 이용목적</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={styles['left']}>
                                <th rowSpan="3">회원가입</th>
                                <td>(필수) 이메일주소, 비밀번호, 이름, 휴대전화번호</td>
                                <td>본인 확인 및 가입의사 확인, 상담 및 문의처리</td>
                            </tr>
                            <tr className={styles['left']}>
                                <td>(선택) 본인확인값(CI, DI) 또는 아이핀인증결과, 추천인 아이디, 법인명, 사이트URL, 앱 보유 여부, 쇼핑몰 운영 여부, 전화번호</td>
                                <td>서비스 이용에 따른 본인 확인, 신규 서비스 안내, 설문, 회원 맞춤형 채용정보 제공</td>
                            </tr>
                            <tr className={styles['left']}>
                                <td>(선택) 마케팅 정보 수신 동의(이메일, 휴대전화)</td>
                                <td>신규 서비스 안내, 설문, 이벤트 관련 정보 및 참여 기회 제공, 광고 및 마케팅 정보 제공</td>
                            </tr>
                            <tr className={styles['left']}>
                                <th>발송서비스 이용</th>
                                <td>(필수) 전화번호, 팩스번호, 이메일, 카카오톡 플러스친구 아이디, 앱 정보(Android, iOS), 사업자 등록번호</td>
                                <td>이메일, 문자(SMS/LMS/MMS), 푸시, 카카오톡 알림톡/친구톡, 팩스, 자동메시징 등의 발송서비스 제공</td>
                            </tr>
                            <tr className={styles['left']}>
                                <th rowSpan="2">마케팅 자동화서비스 이용</th>
                                <td>(필수) 법인명, 사이트 유형(Web/ App), 사이트 URL, 사이트 업종, 사이트 플랫폼</td>
                                <td>서비스 대상 사이트 식별</td>
                            </tr>
                            <tr className={styles['left']}>
                                <td>(선택) 스크립트 설치를 위한 접속 정보 (일반 웹사이트 (FTP주소, FTP 포트, FTP 아이디, FTP 비밀번호), 임대형 쇼핑몰 (어드민 아이디, 비밀번호)), 설치 안내를 위한 담당자 정보 (이름, 이메일주소, 연락처)</td>
                                <td>분석 스크립트 설치 대행</td>
                            </tr>
                            <tr className={styles['left']}>
                                <th>유료서비스 이용</th>
                                <td>(필수) 결제정보(카드(카드사명, 카드번호), 휴대폰(휴대폰번호, 통신사정보), 실시간계좌이체(은행 명, 계좌번호)</td>
                                <td>유료서비스 구매 및 이용 시 요금 정산 <br aria-hidden="true" />
                                    ※ 결제정보는 결제대행업체에서만 저장하며, 당사는 저장 및 관리하고 있지 않습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles['hs-terms-table-wrap']}>
                    <table className={styles['hs-terms-table']}>
                        <caption className={styles['hs-terms-table-caption']}>서비스 이용에 따른 자동 수집 및 생성 정보</caption>
                        <colgroup>
                            <col style={{ width: "50%" }} />
                            <col style={{ width: "50%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>수집항목</th>
                                <th>수집 및 이용목적</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>쿠키, 서비스이용기록(방문일시, IP, 불량이용기록), 모바일 기기정보(디바이스토큰, 디바이스ID, IP주소), PC 정보(브라우저, OS)</td>
                                <td>본인 확인 및 가입의사 확인, 상담 및 문의처리</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>나. 개인정보 제3자 제공</h3>
                <p className={styles['hs-terms-section-text']}>회사는「가. 개인정보 수집 및 이용 현황」에서 고지한 범위 내에서만 개인정보를 이용하며, 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
                <p className={styles['hs-terms-section-text']}>TAS는 이용자의 개인정보를 제3자에게 제공하거나 공유하는 경우에는 사전에 이용자의 개인정보를 제공받거나 공유하게 될 자가 누구이며, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 공유되어야 하는지, 그리고 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 전자우편 및 TAS 내의 공지사항을 통해 고지하여 동의를 구하는 절차를 거치거나, 회원가입 시 별도로 동의를 구할 수도 있습니다. 이용자가 동의하지 않는 경우에는 개인정보를 제공하거나 공유하지 않습니다. 제공관계에 변화가 있거나 제공관계가 종결될 때도 같은 절차에 의하여 고지하거나 동의를 구합니다.</p>
                <p className={`${styles['hs-terms-section-text']} ${styles['warning']}`}>다만, 다음의 경우에는 예외적으로 이용자의 동의 없이 개인정보를 제공할 수 있습니다.</p>
                <ol className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>1) 법령의 규정에 의거하거나, 수사상 목적으로 법령에 정해진 절차와 방법에 따라 관련기관의 요구가 있는 경우</li>
                    <li className={styles['hs-terms-section-list-item']}>2) 유료 서비스 제공에 따른 요금 정산을 위하여 필요한 경우</li>
                    <li className={styles['hs-terms-section-list-item']}>3) 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우</li>
                </ol>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>다. 개인정보 처리위탁</h3>
                <p className={styles['hs-terms-section-text']}>회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 기본서비스 사용위탁을 포함한 자세한 사항은 개인정보 위탁방침을 통해 고지하고 있습니다. 또한 동의를 거부하시고자 하는 경우 회원정보변경을 통해 직접 탈퇴 진행 또는 고객센터로 요청할 수 있습니다. 단, 동의거부에 따른 서비스사용에 제한이 있을 수 있습니다.</p>
                <div className={styles['hs-terms-table-wrap']}>
                    <table className={styles['hs-terms-table']}>
                        <caption className={styles['hs-terms-table-caption']}>개인정보 위탁업체</caption>
                        <colgroup>
                            <col style={{ width: "10%" }} />
                            <col />
                            <col style={{ width: "35%" }} />
                            <col style={{ width: "35%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>수탁업체</th>
                                <th>위탁업무 내용</th>
                                <th>개인정보의 보유 및 이용기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>다우기술</td>
                                <td>SMS, LMS, MMS 발신 서비스제공</td>
                                <td>서비스 제공기간 종료시까지</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>인비토</td>
                                <td>SMS, LMS, MMS 발신 서비스제공</td>
                                <td>서비스 제공기간 종료시까지</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>아이하트</td>
                                <td>SMS, LMS, MMS 발신 서비스제공</td>
                                <td>서비스 제공기간 종료시까지</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>카카오</td>
                                <td>알림톡 발신 서비스제공</td>
                                <td>서비스 제공기간 종료시까지</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>라. 개인정보의 보유 및 이용기간</h3>
                <p className={styles['hs-terms-section-text']}>원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 다만, 아래와 같이 법률의 규정에 의하거나 수사기관에의 제공 등 목적을 위하여, 회원탈퇴 후에도 이용자의 개인정보를 일정한 시점까지 보유할 수 있습니다.</p>
                <p className={`${styles['hs-terms-section-text']} ${styles['warning']}`}>전자상거래등에서의소비자보호에관한법률 등 관계법령이 정하는 경우에는 이용자의 탈퇴 이후에도 법령이 정하는 일정한 기간에 한하여, 이용자의 정보를 보유하게 됩니다.</p>
                <ul className={`${styles['hs-terms-section-list']}`}>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-01">▶ 계약 또는 청약철회 등에 관한 기록</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-01">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 이유 : </dt>
                                <dd>전자상거래 등에서의 소비자보호에 관한 법률</dd>
                            </div>
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>5년</dd>
                            </div>
                        </dl>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-02">▶ 대금결제 및 재화 등의 공급에 관한 기록</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-02">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 이유 : </dt>
                                <dd>전자상거래 등에서의 소비자보호에 관한 법률</dd>
                            </div>
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>3년</dd>
                            </div>
                        </dl>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-03">▶ 소비자의 불만 또는 분쟁처리에 관한 기록</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-03">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 이유 : </dt>
                                <dd>전자상거래 등에서의 소비자보호에 관한 법률</dd>
                            </div>
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>3년</dd>
                            </div>
                        </dl>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-04">▶ 본인확인에 관한 기록</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-04">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 이유 : </dt>
                                <dd>정보통신망 이용촉진 및 정보보호 등에 관한 법률</dd>
                            </div>
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>6개월</dd>
                            </div>
                        </dl>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-05">▶ 방문에 관한 기록</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-05">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 이유 : </dt>
                                <dd>정보통신망 이용촉진 및 정보보호 등에 관한 법률</dd>
                            </div>
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>3개월</dd>
                            </div>
                        </dl>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-06">▶ 회원탈퇴 시 개인정보 보존기간은 아래와 같습니다.</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-06">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 근거 : </dt>
                                <dd>불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조</dd>
                            </div>
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>회원탈퇴 후 1년</dd>
                            </div>
                        </dl>
                    </li>
                    <li className={styles['hs-terms-section-list-item']}>
                        <strong className={styles['hs-terms-user-info-title']} aria-describedby="user-info-term-07">▶ 회원의 주소록 정보</strong>
                        <dl className={styles['hs-terms-user-info-list']} id="user-info-term-07">
                            <div className={styles['hs-terms-user-info-item']}>
                                <dt>보존 기간 : </dt>
                                <dd>
                                    1) 회원탈퇴 시 즉시 파기<br aria-hidden="true" />
                                    2) 휴면계정으로 전환 시점으로부터 12개월 이후 즉시 파기
                                </dd>
                            </div>
                        </dl>
                    </li>
                </ul>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>마. 개인정보 파기절차 및 방법</h3>
                <p className={styles['hs-terms-section-text']}>TAS는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
                <dl className={styles['hs-terms-definition-list']}>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>1) 파기절차</dt>
                        <dd>이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련법령에 의한 정보보호 사유에 따라 일정기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.</dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>2) 파기방법</dt>
                        <dd>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 이용하여 삭제합니다.</dd>
                    </div>
                </dl>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>바. 이용자 및 법정대리인의 권리와 그 행사방법</h3>
                <p className={styles['hs-terms-section-text']}>이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.</p>
                <p className={styles['hs-terms-section-text']}>이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘회원정보수정’ 을, 가입해지를 위해서는 '1:1 고객상담' 을 이용하여 열람, 정정 또는 탈퇴가 가능합니다.</p>
                <p className={styles['hs-terms-section-text']}>귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
                <p className={styles['hs-terms-section-text']}>포스트맨은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘개인정보 파기절차 및 방법’ 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>사. 개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</h3>
                <p className={styles['hs-terms-section-text']}>TAS는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 이용합니다. 쿠키란 포스트맨의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.</p>
                <p className={styles['hs-terms-section-text']}>포스트맨은 다음과 같은 목적을 위해 쿠키를 사용합니다.</p>
                <dl className={styles['hs-terms-definition-list']}>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>1) 쿠키 사용목적</dt>
                        <dd>
                            회원과 비회원의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공합니다. <br aria-hidden="true" />
                            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
                        </dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>2) 쿠키 설정 거부방법</dt>
                        <dd>쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 이용자가 쿠키 설치를 거부하였을 `경우 서비스 제공에 어려움이 있을 수 있습니다.</dd>
                    </div>
                </dl>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>아. 개인정보의 기술적, 관리적 보호 대책</h3>
                <p className={styles['hs-terms-section-text']}>TAS는 회원의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다. 단, 회원 본인의 부주의나 인터넷상의 문제로 개인정보가 유출되어 발생한 문제에 대해서는 일체의 책임을 지지 않습니다.</p>
                <dl className={styles['hs-terms-definition-list']}>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>1) 비밀번호 암호화</dt>
                        <dd>회원의 아이디, 비밀번호는 암호화 되어 저장 및 관리되고 있어 본인만이 알고 있으며 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>2) 해킹 등에 대비한 기술적 대책</dt>
                        <dd>포스트맨은 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.<br aria-hidden="true" />
                            개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 백신 프로그램을 이용하여 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있습니다.<br aria-hidden="true" />
                            또한 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 기타 시스템적으로 보안성을 확보하기 위한 기술적 장치를 갖추려 노력하고 있습니다.</dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>3) 취급 직원의 최소화 및 교육</dt>
                        <dd>TAS의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 강조하고 있습니다.</dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>4) 개인정보보호전담기구의 운영</dt>
                        <dd>사내 개인정보보호전담부서를 통하여 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 인터넷상의 문제로 개인정보가 유출되어 발생한 문제에 대해서는 일체의 책임을 지지 않습니다.</dd>
                    </div>
                </dl>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>자. 권익침해 구제방법</h3>
                <p className={styles['hs-terms-section-text']}>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. (아래의 기관은 개인정보보호 종합지원시스템과는 별개의 기관으로서, 개인정보보호 종합지원시스템의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다)</p>
                <dl className={styles['hs-terms-definition-list']}>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>▶ 개인정보 침해신고센터(한국인터넷진흥원 운영)</dt>
                        <dd>
                            회원과 비회원의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공합니다. <br aria-hidden="true" />
                            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
                        </dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>▶ 개인정보 분쟁조정위원회(한국인터넷진흥원 운영)</dt>
                        <dd>- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br aria-hidden="true" />
                            - 홈페이지 : privacy.kisa.or.kr<br aria-hidden="true" />
                            - 전화 : (국번없이) 118<br aria-hidden="true" />
                            - 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>▶ 대검찰청 사이버범죄수사단</dt>
                        <dd>- 전화 : 02-3480-2000<br aria-hidden="true" />
                            - 홈페이지 : www.spo.go.kr<br aria-hidden="true" />
                            - 이메일 : cybercid@spo.go.kr</dd>
                    </div>
                    <div className={styles['hs-terms-definition-item']}>
                        <dt>▶ 경찰청 사이버안전국 : 182 (www.netan.go.kr)</dt>
                        <dd>
                            또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다. <br aria-hidden="true" />
                            ☞ 중앙행정심판위원회(www.simpan.go.kr)의 전화번호 안내 참조
                        </dd>
                    </div>
                </dl>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>차. 개인정보 처리방침의 변경</h3>
                <p className={styles['hs-terms-section-text']}>
                    현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 '공지사항'을 통해 고지할 것입니다. <br aria-hidden="true" />
                    다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일전에 고지합니다.
                </p>
                <ul className={styles['hs-terms-dash-list']}>
                    <li className={styles['hs-terms-dash-item']}>공고 일자 : 2023년 05월 10일</li>
                    <li className={styles['hs-terms-dash-item']}>시행 일자 : 2023년 05월 17일</li>
                </ul>
                <Link to="/terms/HsTermsPrivacy200416" className={styles['hs-terms-link']}>이전 개인정보 처리방침보기(2020년 4월 16일)</Link>
                <br></br>
                <Link to="/terms/HsTermsPrivacy200716" className={styles['hs-terms-link']}>이전 개인정보 처리방침보기(2020년 7월 16일)</Link>
            </section>
            <section className={styles['hs-terms-section']}>
                <h3 className={styles['hs-terms-section-title']}>타. 개인정보 관리 책임자</h3>
                <p className={styles['hs-terms-section-text']}>TAS는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보관리책임자를 지정하고 있습니다.</p>
                <strong className={styles['hs-terms-user-info-management-title']} aria-describedby="user-info-management">[개인정보관리책임자]</strong>
                <ol className={styles['hs-terms-section-list']} id="user-info-management">
                    <li className={styles['hs-terms-section-list-item']}>1) 부서명: TAS센터 최상호 부사장</li>
                    <li className={styles['hs-terms-section-list-item']}>2) 전화번호: 02-6713-0956</li>
                    <li className={styles['hs-terms-section-list-item']}>3) 이메일: tas@tason.com</li>
                </ol>
            </section>
        </>
    );
}

export default HsTermsPrivacy;